<template>
<div id="resume">
  <div class="resume-wrap">
    <div class="resume-catalogue">
      <div class="resume-progress">
        <h3><span>在线简历</span>
          <router-link :to="{path:'/personal'}"><label>退出编辑</label></router-link>
        </h3>
        <p>简历完成度</p>
        <el-progress :stroke-width="18" :percentage="completionModel.completionDegree"></el-progress>
      </div>
      <div class="catalogue-list">
        <p>必填</p>
        <ul>
          <li><span>基本资料</span><label>20%</label>
            <i v-if="completionModel.basicInfo" class="el-icon-check"></i>
            <i v-else class="el-icon-close"></i>
          </li>
          <li>求职意向<label>20%</label>
            <i v-if="completionModel.jobSeekingIntention" class="el-icon-check"></i>
            <i v-else class="el-icon-close"></i>
          </li>
          <li>工作经历<label>20%</label>
            <i v-if="completionModel.resumeWorkHistory" class="el-icon-check"></i>
            <i v-else class="el-icon-close"></i>
          </li>
        </ul>
        <p>已下3项必填1项</p>
        <ul>
          <li>项目经历<label>10%</label>
             <i v-if="completionModel.resumeProjectExperience" class="el-icon-check"></i>
             <i v-else class="el-icon-close"></i>
          </li>
          <li>教育经历<label>10%</label>
             <i v-if="completionModel.resumeEducation" class="el-icon-check"></i>
             <i v-else class="el-icon-close"></i>
          </li>
        </ul>
        <ul>
          <li>自我评价<label>10%</label>
            <i v-if="completionModel.otherInfo" class="el-icon-check"></i>
             <i v-else class="el-icon-close"></i>
          </li>
          <li>职业技能<label>10%</label>
            <i v-if="completionModel.professionalSkills" class="el-icon-check"></i>
             <i v-else class="el-icon-close"></i>
          </li>
        </ul>
      </div>
    </div>
    <div class="resume-box">
      <!-- 基本资料 -->
      <div class="user-info">
        <div class="user-base" v-show="userEdit">
          <div class="avator">
            <img :src="userForm.headSculpture" />
          </div>
          <div class="user-primary">
            <h3>
              <span>{{userForm.userName}}</span>
            </h3>
            <p>
              <img width="20px" height="21px" src="@/assets/imgs/personal/user.png"><span>{{userForm.age}}岁</span>
              <img width="16px" height="24px" src="@/assets/imgs/location-icon.png"><span>{{userForm.areaName}}</span>
              <img width="23px" height="21px" src="@/assets/imgs/edu-icon.png"><span>{{userForm.educationName}}</span>
              <img width="20px" height="20px" src="@/assets/imgs/time-icon.png"><span>{{userForm.workExperienceName}}</span>
            </p>
            <p class="u-contact">
              <img width="24px" height="24px" src="@/assets/imgs/personal/phoneIcon.png"><span>{{userForm.phone}}</span>
              <img width="24px" height="24px" src="@/assets/imgs/personal/email.png"><span>{{userForm.mailbox}}</span>
            </p>
          </div>
          <div class="operate" v-show="userEdit" @click="userEdit=false">
            <img src="@/assets/imgs/personal/edit.png" />
            <span>编辑</span>
          </div>
        </div>
        <div class="user-edit" v-show="!userEdit">
          <el-upload class="avatar-uploader" :on-success="avatarSuccess" :action="baseUrl" :show-file-list="false">
            <img v-if="userForm.headSculpture" :src="userForm.headSculpture" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="user-edit-ct formStyle userFormStyle">
            <el-form ref="form" :model="userForm" :inline="true" label-width="90px">
              <el-form-item label="姓名">
                <el-input clearable placeholder="请输入姓名" v-model="userForm.userName"></el-input>
              </el-form-item>
              <el-form-item label="性别">
                <el-radio-group v-model="userForm.sex">
                  <el-radio label="0">男</el-radio>
                  <el-radio label="1">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="出生年月">
                <el-date-picker clearable type="date" placeholder="选择日期" value-format="yyyy-MM-dd" v-model="userForm.dateOfBirth" style="width: 100%;"></el-date-picker>
              </el-form-item>
              <el-form-item label="所在区域">
                 <el-cascader  v-model="userForm.citys" :show-all-levels="false" @change="selectCitys" :options="areaList" placeholder="请选择所在区域">
                 </el-cascader>
              </el-form-item>
              <el-form-item label="电话号码">
                <el-input :disabled="true" v-model="userForm.phone"></el-input>
              </el-form-item>
              <el-form-item label="所用邮箱">
                <el-input clearable placeholder="请输入所用邮箱" v-model="userForm.mailbox"></el-input>
              </el-form-item>
              <el-form-item label="工作经验">
                <el-select clearable v-model="userForm.workExperience" placeholder="请选择工作经验">
                  <el-option v-for="item in workList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="最高学历">
                <el-select clearable v-model="userForm.education" placeholder="请选择最高学历">
                  <el-option v-for="item in backgroundTypeList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn-box">
              <el-button @click="userEdit=true">取消</el-button>
              <el-button type="primary" @click="setBasicInfo">保存</el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 求职意愿 -->
      <div class="summary">
        <div class="summary-title"><span></span>求职意向</div>
        <div class="operate" @click="summaryEdit=false">
          <img src="@/assets/imgs/personal/edit.png" />
          <span>编辑</span>
        </div>
        <div class="summary-base" v-show="summaryEdit==true">
          <p>
            <span>期望职位：</span><label>{{summaryForm.workFunctionName}}</label>
          </p>
          <p>
            <span>期待行业：</span><label>{{summaryForm.engagedInIndustryName}}</label>
          </p>
          <p>
            <span>期望区域：</span><label>{{summaryForm.expectedCityName}}</label>
          </p>
          <p>
            <span>期望薪资：</span>
            <label v-show="summaryForm.minimumWage=='面议'">面议</label>
            <label v-show="summaryForm.minimumWage!='面议'">{{summaryForm.minimumWage}} - {{summaryForm.maximumSalary}}元</label>
          </p>
          <p>
            <span>求职状态：</span><label>{{summaryForm.jobStatusName }}</label>
          </p>
        </div>
        <div class="summary-edit formStyle" v-show="summaryEdit==false">
          <el-form ref="form" :model="userForm" :inline="true" label-width="90px">
            <el-form-item label="期望岗位">
                <el-input @focus="openPosition" placeholder="请选择期望岗位" v-model="summaryForm.workFunctionName"></el-input>
            </el-form-item>
            <el-form-item label="从事行业">
              <el-cascader @change="selectIndustry"  v-model="summaryForm.engagedInIndustry" :options="companyIndustryList" placeholder="请选择期望岗位" :props="{
                    value:'code',
                    label:'name',
                    children:'subLevelModelList'
                   }" :show-all-levels="false">
              </el-cascader>
            </el-form-item>
            <el-form-item label="期望区域">
                <el-cascader  v-model="summaryForm.expectedCitys" :show-all-levels="false" @change="selectExpectedCitys" :options="areaList" placeholder="请选择所在区域">
                </el-cascader>
            </el-form-item>
            <el-form-item label="期望薪资">
              <el-select  @change="selectSalary" v-model="summaryForm.minimumWage" placeholder="请选择">
                <el-option v-for="item in salaryList" :key="item.salary" :label="item.salary" :value="item.salary">
                </el-option>
              </el-select>
              <span class="until">至</span>
              <el-select  v-model="summaryForm.maximumSalary" placeholder="请选择">
                <el-option v-for="item in maxSalaryList" :key="item.salary" :label="item.salary" :value="item.salary">
                </el-option>
              </el-select>
              <span class="until">元</span>
            </el-form-item>
            <el-form-item label="求职状态">
              <el-select  v-model="summaryForm.jobStatus" placeholder="请选择求职状态">
                <el-option v-for="item in jobStatusList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="btn-box">
            <el-button @click="summaryEdit=true">取消</el-button>
            <el-button type="primary" @click="savejobSeek">保存</el-button>
          </div>
        </div>
      </div>
      <!-- 教育经历 -->
      <div class="summary">
        <div class="summary-title edu-title"><span></span>教育经历</div>
        <div class="operate" @click="addEdu()">
          <img src="@/assets/imgs/personal/add.png" />
          <span>添加</span>
        </div>
        <div class="education-base" v-for="(item,index) in educationList" :key="index">
          <div class="education-header">
            <span>学校名称：<label>{{item.schoolName}}</label></span>
            <div class="edu-btn" @click="editEdu(item)">
              <img src="@/assets/imgs/personal/edit.png" />
              <em>编辑</em>
            </div>
            <div class="edu-btn" @click="removeEducation(item.id)">
              <img src="@/assets/imgs/personal/delBtn.png" />
              <em>删除</em>
            </div>
          </div>
          <p>就读时间：{{item.admissionTime}} - {{item.departureTime}}</p>
          <p>专业：{{item.major}} | 学历：{{item.educationName}}</p>
        </div>
        <div class="education-edit formStyle" v-show="edutionEdit">
          <h3>添加/编辑教育经历</h3>
          <el-form ref="form" :model="educationForm" :inline="true" label-width="90px">
            <el-form-item label="学校名称">
              <el-input clearable placeholder="请输入学校名称" v-model="educationForm.schoolName"></el-input>
            </el-form-item>
            <el-form-item label="所学专业">
              <el-input clearable placeholder="请输入所学专业" v-model="educationForm.major"></el-input>
            </el-form-item>
            <el-form-item label="最高学历">
              <el-select clearable v-model="educationForm.highestEducation" placeholder="请选择最高学历">
                <el-option v-for="item in backgroundTypeList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="就读时间">
              <el-date-picker value-format="yyyy-MM-dd" clearable type="date" placeholder="选择时间" v-model="educationForm.admissionTime"></el-date-picker>
              <span class="until">至</span>
              <el-date-picker value-format="yyyy-MM-dd" clearable type="date" placeholder="选择时间" v-model="educationForm.departureTime"></el-date-picker>
            </el-form-item>
          </el-form>
          <div class="btn-box">
            <el-button @click="edutionEdit=false">取消</el-button>
            <el-button type="primary" @click="saveEdution">保存</el-button>
          </div>
        </div>
      </div>
      <!-- 工作经历 -->
      <div class="summary">
        <div class="summary-title edu-title"><span></span>工作经历</div>
        <div class="operate" @click="addWork">
          <img src="@/assets/imgs/personal/add.png" />
          <span>添加</span>
        </div>
        <div class="education-base" v-for="(item,index) in workHistoryList" :key="index">
          <div class="education-header">
            <span>公司名称：{{item.unitName}}</span>
            <div class="edu-btn" @click="editWork(item)">
              <img src="@/assets/imgs/personal/edit.png" />
              <em>编辑</em>
            </div>
            <div class="edu-btn" @click="delWork(item.id)">
              <img src="@/assets/imgs/personal/delBtn.png" />
              <em>删除</em>
            </div>
          </div>
          <p>就职时间：{{item.entryTime}} - {{item.leavedate}}</p>
          <p class="job-name">担任职位：{{item.positionName}}</p>
          <p class="des-ct"><span>工作描述：</span><label>{{item.jobDescription}}</label></p>
        </div>
        <div class="education-edit formStyle" v-show="workEdit">
          <h3>添加/编辑工作经历</h3>
          <el-form ref="form" :model="workForm" :inline="true" label-width="90px">
            <el-form-item label="公司名称">
              <el-input clearable placeholder="请输入公司名称" v-model="workForm.unitName"></el-input>
            </el-form-item>
            <el-form-item label="担任职位">
              <el-cascader @change="selectWPosition"  clearable v-model="workForm.position" :options="positionList" placeholder="请输入担任职位" :props="{
                    value:'code',
                    label:'name',
                    children:'subLevelModelList'
                   }" :show-all-levels="false">
              </el-cascader>
            </el-form-item>
            <el-form-item label="就职时间">
              <el-date-picker value-format="yyyy-MM-dd" clearable type="date" placeholder="选择时间" v-model="workForm.entryTime"></el-date-picker>
              <span class="until">至</span>
              <el-date-picker value-format="yyyy-MM-dd" clearable type="date" placeholder="选择时间" v-model="workForm.leavedate"></el-date-picker>
            </el-form-item>
            <el-form-item label="工作描述">
              <el-input type="textarea" clearable placeholder="请输入工作描述" v-model="workForm.jobDescription"></el-input>
            </el-form-item>
          </el-form>
          <div class="btn-box">
            <el-button @click="workEdit=false">取消</el-button>
            <el-button type="primary" @click="saveWrok">保存</el-button>
          </div>
        </div>
      </div>
      <!-- 项目经历 -->
      <div class="summary">
        <div class="summary-title edu-title"><span></span>项目经历</div>
        <div class="operate" @click="addProject">
          <img src="@/assets/imgs/personal/add.png" />
          <span>添加</span>
        </div>
        <div class="education-base" v-for="(item,index) in projectList" :key="index">
          <div class="education-header projcet-header">
            <span>项目名称：</span>
            <label>{{item.projectName}}</label>
            <div class="edu-btn" @click="editProject(item)">
              <img src="@/assets/imgs/personal/edit.png" />
              <em>编辑</em>
            </div>
            <div class="edu-btn" @click="delProject(item.id)">
              <img src="@/assets/imgs/personal/delBtn.png" />
              <em>删除</em>
            </div>
          </div>
          <p class="project-time"><span>项目时间：</span><label>{{item.startTime}} 至 {{item.endTime}}</label></p>
           <p class="des-ct"><span>项目描述：</span><label>{{item.projectDescription}}</label></p>
        </div>
        <div class="education-edit formStyle" v-show="projectEdit">
          <h3>添加/编辑项目经历</h3>
          <el-form ref="form" :model="projectForm" :inline="true" label-width="90px">
            <el-form-item label="项目名称">
              <el-input class="longInput" clearable placeholder="请输入项目名称" v-model="projectForm.projectName"></el-input>
            </el-form-item>
            <el-form-item label="项目时间">
              <el-date-picker value-format="yyyy-MM-dd" clearable type="date" placeholder="选择时间" v-model="projectForm.startTime"></el-date-picker>
              <span class="until">至</span>
              <el-date-picker value-format="yyyy-MM-dd" clearable type="date" placeholder="选择时间" v-model="projectForm.endTime	"></el-date-picker>
            </el-form-item>
            <el-form-item label="项目描述">
              <el-input type="textarea" clearable placeholder="请输入项目描述" v-model="projectForm.projectDescription"></el-input>
            </el-form-item>
          </el-form>
          <div class="btn-box">
            <el-button @click="projectEdit=false">取消</el-button>
            <el-button type="primary" @click="saveProject">保存</el-button>
          </div>
        </div>
      </div>
      <!-- 自我评价 -->
      <div class="summary">
        <div class="summary-title edu-title"><span></span>自我评价</div>
        <div class="operate" @click="appraiseEdit=false">
          <img src="@/assets/imgs/personal/edit.png" />
          <span>编辑</span>
        </div>
        <div class="education-base" v-show="appraiseEdit">
          <p>{{selfEvaluation}}</p>
        </div>
        <div class="education-edit formStyle" v-show="!appraiseEdit">
          <el-form ref="form" :inline="true" label-width="90px">
            <el-form-item>
              <el-input type="textarea" clearable placeholder="请输入自我评价" v-model="selfEvaluation"></el-input>
            </el-form-item>
          </el-form>
          <div class="btn-box">
            <el-button @click="appraiseEdit=true">取消</el-button>
            <el-button type="primary" @click="saveOther">保存</el-button>
          </div>
        </div>
      </div>
      <!-- 职业技能 -->
      <div class="summary">
        <div class="summary-title edu-title"><span></span>职业技能</div>
        <div class="operate" @click="addSkill">
          <img src="@/assets/imgs/personal/add.png" />
          <span>添加</span>
        </div>
        <div class="education-base" v-for="(item,index) in skillList" :key="index">
          <div class="education-header">
            <span>{{item.skillName}}</span>
            <label>{{item.proficiencyName}}</label>
            <div class="edu-btn" @click="editSkill(item)">
              <img src="@/assets/imgs/personal/edit.png" />
              <em>编辑</em>
            </div>
            <div class="edu-btn" @click="delSkill(item.id)">
              <img src="@/assets/imgs/personal/delBtn.png" />
              <em>删除</em>
            </div>
          </div>

        </div>
        <div class="education-edit formStyle" v-show="skillEdit">
          <h3>添加/编辑职业技能</h3>
          <el-form ref="form" :model="projectForm" :inline="true" label-width="90px">
            <el-form-item label="技能名称">
              <el-input class="longInput" clearable placeholder="请输入技能名称" v-model="skillForm.skillName"></el-input>
            </el-form-item>
            <el-form-item label="熟练度">
              <el-select clearable v-model="skillForm.proficiency" placeholder="请选择熟练度">
                <el-option v-for="item in proficiencyList" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="btn-box">
            <el-button @click="skillEdit=false">取消</el-button>
            <el-button type="primary" @click="saveSkill">保存</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="positionDialog">
        <el-dialog
          title="期望职位（最多可以选择五项）"
          :visible.sync="showPosition"
          width="900px"
          >
          <div class="positionBox">
            <div class="positionTags">
               <el-tag
                  :key="tag.code"
                  v-for="tag in workFunctionTags"
                  closable
                  :disable-transitions="false"
                  @close="closeTags(tag)">
                  {{tag.name}}
                </el-tag>
            </div>
             <div class="positionItem" v-for="(item,index) in positionList" :key="index">
                <div class="positionItem-left">
                   <span>{{item.name}}</span>
                </div>
                <div class="positionItem-right" >
                   <div class="subPosition" v-for="(sub,key) in item.subLevelModelList" :key="key">
                      <h3>{{sub.name}}</h3>
                      <div class="threeLevel" v-for="(g,m) in sub.subLevelModelList" :key="m">
                        <el-checkbox @change="selectCheck" v-model="g.check">{{g.name}}</el-checkbox>
                      </div>
                   </div>
                </div>
              </div>  
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="showPosition = false">取 消</el-button>
            <el-button type="primary" @click="surePosition">确 定</el-button>
          </span>
        </el-dialog>
    </div>
  </div>
</div>
</template>

<script>
import {getResumeCompletionRate, resumeEducation, removeProfessionalSkills, removeWorkHistory, removeProjectExperience, removeEducation, resumeProfessionalSkills, resumeOtherInfo, projectExperience, workHistory, getUrbanAreaList, getDictData, setBasicInfo, getUserInfo, jobSeekingIntention, getSalaryRequirements, getResumeInfo, getPosition, getCompanyIndustry } from "@/api/index.js";
export default {
  name: "resume",
  data() {
    return {
      userEdit: false,
      summaryEdit: true,
      edutionEdit: false,
      workEdit: false,
      projectEdit: false,
      appraiseEdit: true,
      skillEdit: false,
      baseUrl: '',
      areaList: [],
      workList: [],
      positionList: [], //职位列表
      backgroundTypeList: [],
      companyIndustryList: [], //公司行业
      salaryList: [],
      maxSalaryList: [],
      jobStatusList: [],
      proficiencyList: [],
      userForm: {
        citys:[],
        areaName: '',
        dateOfBirth: '',
        age: '',
        education: '',
        educationName: '',
        mailbox: '',
        sex: '',
        userName: '',
        workExperience: '',
        workExperienceName: '',
        headSculpture: '',
        phone: ''
      },
      summaryForm: {
        engagedInIndustry: '',
        engagedInIndustryName: '',
        expectedCitys: [],
        expectedCityName: '',
        jobStatus: '',
        jobStatusName: '',
        maximumSalary: '',
        minimumWage: '',
        timeOfArrival: '',
        workFunction: '',
        workFunctionName: ''
      },
      educationForm: {
        schoolName: '',
        major: '',
        admissionTime: '',
        departureTime: '',
        highestEducation: ''
      },
      workForm: {
        entryTime: '',
        id: '',
        jobDescription: '',
        leavedate: '',
        position: '',
        unitName: '',
        upToNow: ''
      },
      projectForm: {
        endTime: '',
        id: '',
        projectDescription: '',
        projectName: '',
        startTime: ''
      },
      skillForm: {
        id: '',
        proficiency: '',
        proficiencyName: '',
        skillName: ''
      },
      selfEvaluation: '',
      workHistoryList: [],
      educationList: [],
      projectList: [],
      skillList: [],
      completionModel:{},
      showPosition:false,
      workFunctionTags:[]
    }
  },
  mounted() {
    this.getAreaList()
    this.getUserInfo()
    this.getPosition()
    this.getCompanyIndustry();
    this.getSalaryRequirements()
    this.getDictData('work_experience_two')
    this.getDictData('background_type_two')
    this.getDictData('job_status')
    this.getDictData('proficiency_type')
    this.baseUrl = process.env.VUE_APP_BASE_API + '/webApi/common/upload'
  },
  methods: {
    selectCitys(val){
      this.userForm.citys = val
    },
    selectExpectedCitys(val){
      this.summaryForm.expectedCitys = val
    },
    closeTags(tag){
      this.workFunctionTags.splice(this.workFunctionTags.indexOf(tag), 1);
       this.positionList.forEach(item=>{
          item.subLevelModelList.forEach(s=>{
            s.subLevelModelList.forEach(g=>{
              g.check = false
            })
          })
      })
      this.workFunctionTags.forEach(w=>{
          this.positionList.forEach(item=>{
            item.subLevelModelList.forEach(s=>{
              s.subLevelModelList.forEach(g=>{
                if(w.code==g.code){
                   g.check = true
                }
              })
            })
          })
      })
     
    },
    surePosition(){
      var workFunction = []
      var workFunctionName = []

      this.positionList.forEach(item=>{
        item.subLevelModelList.forEach(s=>{
          s.subLevelModelList.forEach(g=>{
            if(g.check){
              workFunction.push(g.code)
              workFunctionName.push(g.name)
            }
          })
        })
      })
      this.summaryForm.workFunction = workFunction.join(',')
      this.summaryForm.workFunctionName = workFunctionName.join(',')
      this.showPosition = false

    },
    selectCheck(){
      this.workFunctionTags = []
      this.positionList.forEach(item=>{
        item.subLevelModelList.forEach(s=>{
          s.subLevelModelList.forEach(g=>{
            if(g.check){
               if(this.workFunctionTags.length>=5){
                this.$message({
                    message: '最多可以选择五项',
                    type: 'warning'
                  })
                  g.check  =false
               }else{
                this.workFunctionTags.push(g)
              }
            }
          })
        })
      })
    },
    openPosition(){
      this.showPosition = true
    },
    getResumeInfo() {
      getResumeInfo({
         userInfoId:JSON.parse(localStorage.getItem('userInfo')).id
      }).then(res => {
        var info = res.data
        this.getResumeCompletionRate()
        var date = new Date()
        if(info.resumeBasicInfo.city){
          this.userForm.citys =info.resumeBasicInfo.city.split(',');
        }

        this.userForm.headSculpture =info.recruitUserInfo.headSculpture;
        this.userForm.dateOfBirth = info.recruitUserInfo.dateOfBirth;
        this.userForm.education = info.resumeBasicInfo.education
        this.userForm.mailbox =info.recruitUserInfo.mailbox;
        this.userForm.sex = info.recruitUserInfo.sex;
        this.userForm.userName = info.recruitUserInfo.userName;
        this.userForm.workExperience = info.resumeBasicInfo.workExperience
        if(info.resumeJobDesire.expectedCity){
          this.summaryForm.expectedCitys =info.resumeJobDesire.expectedCity.split(',');
        }
        this.userForm.areaName = info.resumeBasicInfo.areaName
        this.userForm.workExperienceName = info.resumeBasicInfo.workExperienceName
        this.userForm.educationName =  info.resumeBasicInfo.educationName
        if (this.userForm.dateOfBirth) {
          this.userForm.age = date.getFullYear() - this.userForm.dateOfBirth.split('-')[0]
        }

        //求职意向
         this.summaryForm.expectedCityName =  info.resumeJobDesire.expectedCityName;
        this.summaryForm.workFunction = info.resumeJobDesire.workFunction;
        this.summaryForm.engagedInIndustry = info.resumeJobDesire.engagedInIndustry;
        this.summaryForm.minimumWage = info.resumeJobDesire.minimumWage;
        this.summaryForm.maximumSalary = info.resumeJobDesire.maximumSalary;
        this.summaryForm.timeOfArrival = info.resumeJobDesire.timeOfArrival;
        this.summaryForm.jobStatus = info.resumeJobDesire.jobStatus;
        this.summaryForm.jobStatusName = info.resumeJobDesire.jobStatusName;
        this.summaryForm.workFunctionName = info.resumeJobDesire.workFunctionName;

        this.companyIndustryList.forEach(item => {
          item.subLevelModelList.forEach(s => {
            if (s.code == this.summaryForm.engagedInIndustry) {
              this.summaryForm.engagedInIndustryName = s.name
            }
          })
        })
       
        
        //教育经历
        this.educationList =info.resumeEducationList
        this.educationList.forEach(s => {
          this.backgroundTypeList.forEach(item => {
            if (item.dictValue == s.highestEducation) {
              s.educationName = item.dictLabel
            }
          })
        })
        //工作经历
        this.workHistoryList = info.resumeWorkHistoryList
         this.workHistoryList.forEach(item=>{
          this.positionList.forEach(s=>{
            s.subLevelModelList.forEach(g=>{
              g.subLevelModelList.forEach(l=>{
                if(item.position == l.code){
                  item.positionName = l.name
                }
              })
            })
          })
         })

        this.projectList =info.resumeProjectExperienceList
        this.selfEvaluation = info.resumeOtherInfo.selfEvaluation
        this.skillList = info.resumeProfessionalSkillsList
        this.skillList.forEach(s => {
          this.proficiencyList.forEach(item => {
            if (item.dictValue == s.proficiency) {
              s.proficiencyName = item.dictLabel
            }
          })
        })
      })
    },
     getResumeCompletionRate(){
       getResumeCompletionRate().then(res => {
        this.completionModel = res.data
      })
    },
    getUserInfo() {
      getUserInfo().then(res => {
        this.userForm.phone = res.data.phone
      })
    },
    getSalaryRequirements() {
      getSalaryRequirements().then(res => {
        this.salaryList = res.rows
      })
    },
    selectWPosition(item){
      this.workForm.position = item[2]
    },
    selectSalary(val) {
      this.minSalaryList = []
      this.summaryForm.maximumSalary = ''
      if(val=='面议'){
        return
      }
      this.salaryList.forEach(s => {
        if (s.salary == val) {
          this.maxSalaryList = s.salaryList
        }
      })

    },
    getPosition() {
      getPosition().then(res => {
        this.positionList = res.rows
      
      })
    },
    getCompanyIndustry() {
      getCompanyIndustry().then(res => {
        this.companyIndustryList = res.rows
      })
    },
  
    selectIndustry(val) {
      this.summaryForm.engagedInIndustry = val[1]
    },
    getAreaList() {
      getUrbanAreaList().then(res => {
        this.areaList = res.data
        this.getResumeInfo()
      })
    },
    getDictData(dictType) {
      getDictData({
        dictType: dictType
      }).then(res => {
        if (dictType == 'work_experience_two') {
          this.workList = res.rows
        }
        if (dictType == 'background_type_two') {
          this.backgroundTypeList = res.rows
        }
        if (dictType == 'job_status') {
          this.jobStatusList = res.rows
        }
        if (dictType == 'proficiency_type') {
          this.proficiencyList = res.rows
        }
      })
    },
    avatarSuccess(res) {
      this.userForm.headSculpture = res.url
    },
    setBasicInfo() {
      setBasicInfo(this.userForm).then(res => {
        console.log(res)
        if (res.code == 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.getResumeInfo()
          this.userEdit = true
        }else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }

      })
    },
    savejobSeek() {
      jobSeekingIntention({
        workFunction: this.summaryForm.workFunction,
        engagedInIndustry: this.summaryForm.engagedInIndustry,
        expectedCitys: this.summaryForm.expectedCitys,
        jobStatus: String(this.summaryForm.jobStatus),
        minimumWage: this.summaryForm.minimumWage,
        maximumSalary: this.summaryForm.maximumSalary,
        timeOfArrival: this.summaryForm.timeOfArrival
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.getResumeInfo()
          this.summaryEdit = true
        }else{
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    addEdu() {
      this.educationForm.id = ''
      this.educationForm.schoolName = ''
      this.educationForm.major = ''
      this.educationForm.admissionTime = ''
      this.educationForm.departureTime = ''
      this.educationForm.highestEducation = ''
      this.edutionEdit = true
    },
    editEdu(item) {
      this.edutionEdit = true
      this.educationForm.id = item.id
      this.educationForm.schoolName = item.schoolName
      this.educationForm.major = item.major
      this.educationForm.admissionTime = item.admissionTime
      this.educationForm.departureTime = item.departureTime
      this.educationForm.highestEducation = item.highestEducation
    },
    saveEdution() {
      this.educationForm.highestEducation = String(this.educationForm.highestEducation)
      resumeEducation(this.educationForm).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.getResumeInfo()
          this.edutionEdit = false
        }else{
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    removeEducation(id) {
      removeEducation({ id: id }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getResumeInfo()
        }
      })
    },
    addWork() {
      this.workEdit = true
      this.workForm.entryTime = ''
      this.workForm.id = ''
      this.workForm.jobDescription = ''
      this.workForm.leavedate = ''
      this.workForm.position = ''
      this.workForm.unitName = ''
      this.workForm.upToNow = ''
    },
    editWork(row) {
      this.workEdit = true
      this.workForm.entryTime = row.entryTime
      this.workForm.id = row.id
      this.workForm.jobDescription = row.jobDescription
      this.workForm.leavedate = row.leavedate
      this.workForm.position = row.position
      this.workForm.unitName = row.unitName
      this.workForm.upToNow = row.upToNow
      console.log(row)
    },
    delWork(id) {
      removeWorkHistory({ id: id }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getResumeInfo()
        }
      })
    },
    saveWrok() {
      workHistory(this.workForm).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.getResumeInfo()
          this.workEdit = false
        }else{
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
      console.log(this.workForm)
    },
    addProject() {
      this.projectEdit = true
      this.projectForm.endTime = ''
      this.projectForm.id = ''
      this.projectForm.projectDescription = ''
      this.projectForm.projectName = ''
      this.projectForm.startTime = ''
    },
    editProject(item) {
      this.projectEdit = true
      this.projectForm.endTime = item.endTime
      this.projectForm.id = item.id
      this.projectForm.projectDescription = item.projectDescription
      this.projectForm.projectName = item.projectName
      this.projectForm.startTime = item.startTime
    },
    delProject(id) {
      removeProjectExperience({ id: id }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getResumeInfo()
        }
      })
    },
    saveProject() {
      projectExperience(this.projectForm).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.getResumeInfo()
          this.projectEdit = false
        }else{
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    saveOther() {
      resumeOtherInfo({
        selfEvaluation: this.selfEvaluation
      }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.getResumeInfo()
          this.appraiseEdit = true
        }else{
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    addSkill() {
      this.skillEdit = true
      this.skillForm.id = ''
      this.skillForm.proficiency = ''
      this.skillForm.skillName = ''
    },
    editSkill(item) {
      this.skillEdit = true
      this.skillForm.id = item.id
      this.skillForm.proficiency = item.proficiency
      this.skillForm.skillName = item.skillName
    },
    delSkill(id) {
      removeProfessionalSkills({ id: id }).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getResumeInfo()
        }
      })
    },
    saveSkill() {
      resumeProfessionalSkills(this.skillForm).then(res => {
        if (res.code == 200) {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.getResumeInfo()
          this.skillEdit = false
        }else{
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
#resume {
  width: 100%;
  min-height: 800px;
  padding: 24px 0;
  background: linear-gradient(0deg, #F5F5F5 1%, #D6E9FF 100%);
  overflow: hidden;
}
.positionBox{
  overflow: hidden;
  .positionItem{
    min-height: 50px;
    overflow: hidden;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    padding-bottom: 5px;
    
    .positionItem-left{
      float: left;
      width: 130px;
      font-weight: 600;
      font-size: 14px;
      padding-top: 10px;
      padding-left: 10px;
    }
    .positionItem-right{
      float: left;
      width: 700px;
      h3{
        font-size: 14px;
        clear: both;
       height:40px;
       line-height:40px;
      }
      .threeLevel{
        float: left;
        min-width: 128px;
        margin-bottom: 5px;
        margin-right: 10px;
      }
    }
  }
}
.resume-wrap {
  width: 1200px;
  min-height: 500px;
  overflow: hidden;
  margin: 0 auto;
}

.resume-catalogue {
  width: 405px;
  float: left;

  .resume-progress {
    height: 180px;
    background: #FFFFFF;
    padding: 0 20px;
    border-radius: 10px;

    h3 {
      height: 72px;
      line-height: 72px;
      border-bottom: 1px solid #ddd;

      span {
        font-size: 20px;
        color: #222222;
      }

      label {
        display: block;
        float: right;
        font-weight: 100;
        font-size: 16px;
        color: #666666;
      }
    }

    p {
      font-size: 16px;
      color: #666666;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  .catalogue-list {
    clear: both;
    width: 365px;
    min-height: 500px;
    background: #FFFFFF;
    margin-top: 10px;
    padding: 0 20px;
    border-radius: 10px;

    p {
      height: 73px;
      line-height: 73px;
      font-size: 20px;
      color: #222222;
      border-bottom: 1px solid #ddd;
    }

    ul {
      li {
        height: 48px;
        font-size: 16px;
        color: #222222;
        line-height: 48px;
        cursor: pointer;

        label {
          display: inline-block;
          font-size: 16px;
          color: #666666;
          margin-left: 15px;
        }

        .el-icon-check {
          float: right;
          font-size: 20px;
          margin-top: 15px;
          color: #0077FF;
        }
        .el-icon-close {
          float: right;
          font-size: 20px;
          margin-top: 15px;
          color: #f90c37;
        }
      }
    }
  }
}

.resume-box {
  float: left;
  width: 785px;
  margin-left: 10px;
}

.user-info {
  width: 740px;
  min-height: 120px;
  padding: 30px 20px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;

  .avator {
    float: left;

    img {
      width: 110px;
      height: 110px;
    }

    margin-right: 14px;
  }

  .user-primary {
    float: left;

    h3 {
      font-size: 20px;
      font-weight: 500;
      color: #2A2C33;
      margin-top: 6px;

      img {
        width: 24px;
        height: 24px;
        margin-left: 15px;
      }
    }

    p {
      padding-top: 10px;
      padding-bottom: 12px;
      border-bottom: 1px solid #ddd;
      font-size: 14px;
      color: #999999;
      width: 610px;

      img {
        position: relative;
        top: 4px;
        margin-right: 8px;
      }

      span {
        display: inline-block;
        margin-right: 45px;
      }
    }

    .u-contact {
      border-bottom: none;

      img {
        top: 6px;
      }
    }
  }

}

.user-edit {
  overflow: hidden
}

.user-edit-ct {
  width: 623px;
  float: right;
}

.operate {
  width: 84px;
  height: 50px;
  position: absolute;
  right: 0;
  top: 35px;
  cursor: pointer;

  img {
    width: 18px;
    height: 18px;
  }

  span {
    display: inline-block;
    margin-left: 9px;
    font-size: 14px;
    color: #0077FF;
    position: relative;
    top: -3px;
  }
}

.btn-box {
  float: right;
  margin-top: 10px;
  margin-right: 7px;
}

.summary {
  width: 740px;
  min-height: 120px;
  padding: 30px 20px;
  background: #FFFFFF;
  border-radius: 10px;
  position: relative;
  margin-top: 10px;
  overflow: hidden;

  .summary-title {
    font-size: 20px;
    color: #2A2C33;
    margin-bottom: 20px;

    span {
      display: inline-block;
      width: 3px;
      height: 24px;
      background: #0077FF;
      border-radius: 2px;
      margin-right: 15px;
      position: relative;
      top: 3px;
    }
  }

  .operate {
    top: 30px;
  }

  .summary-base {
    p {
      font-size: 16px;
      color: #999999;
      line-height: 40px;
      margin-left: 20px;

      label {
        color: #222222;
        display: inline-block;
        margin-right: 20px;
      }
    }
  }
}

.until {
  display: inline-block;
  margin: 0 14px 0 20px;
}

.edu-title {
  margin-bottom: 5px !important;
}

.education-base {
  overflow: hidden;
  padding-top: 20px;
  padding-bottom: 6px;
  white-space: pre-wrap;

  .education-header {
    margin-bottom: 16px;

    span {
      font-size: 16px;
      color: #2A2C33;
      display: inline-block;
      margin-right: 20px;
    }

    label {
      font-size: 16px;
      color: #999999;
    }

  }

  .projcet-header {
    span {
      font-size: 16px;
      color: #999999;
      margin-right: 5px;
    }

    label {
      font-size: 16px;
      color: #666666;
    }
  }

  .edu-btn {
    float: right;
    cursor: pointer;

    em {
      font-style: normal;
      font-size: 14px;
      color: #0077FF;
      display: inline-block;
      margin-left: 9px;
      position: relative;
      bottom: 4px;
    }

    img {
      width: 18px;
      height: 18px;
      margin-left: 25px;
    }
  }

  p {
    margin-bottom: 6px;
    font-size: 16px;
    color: #666666;
    line-height: 30px;

    span {
      display: block;
      width: 80px;
      float: left;
    }

    label {
      display: block;
      width: 660px;
      float: left;
      white-space: pre-wrap;
    }
  }

  .job-name {
    font-size: 16px;
    color: #222222;
  }
  .des-ct{
    span{
      display: block;
      float: left;
      label{
        display: block;
        float: left;
      }
    }
  }
  li {
    font-size: 16px;
    color: #666666;
    line-height: 30px;
  }

  .project-time {
    height: 32px;

    span {
      font-size: 16px;
      color: #999999;
    }

    label {
      color: #666666;
    }
  }

  .project-des {
    position: relative;
    top: -7px;
  }
}

.education-edit {
  clear: both;
  padding-top: 14px;

  h3 {
    font-size: 16px;
    font-weight: 500;
    color: #222222;
    margin-bottom: 28px;

  }
}
</style><style lang="scss">
.user-edit {
  .avatar-uploader {
    width: 110px;
    height: 110px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 50%;
    display: inline-block;
  }

  .avatar {
    width: 110px;
    height: 110px;
    border-radius: 50%;
  }

  .avatar-uploader-icon {
    font-size: 66px;
    color: #ddd;
    margin-top: 20px;
  }
}

.formStyle {
  .el-input__inner {
    width: 231px;
    height: 35px;
    background: #EEEEEE;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #666666;
  }

  .longInput {
    .el-input__inner {
      width: 499px;
    }
  }

  .el-textarea__inner {
    width: 566px;
    height: 150px;
    background: #EEEEEE;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    color: #666666;
  }

  .el-form-item__label {
    font-size: 16px;
    color: #999999;
  }

  .el-form--inline .el-form-item {
    margin-bottom: 10px;
  }

  .el-button--primary {
    background: #0077FF;
    border: none;
  }

  .el-tag.el-tag--info {
    background: #D6E2F0;
    font-size: 12px;
    border: none;
    color: #0077FF;
  }

  .el-select .el-tag__close.el-icon-close {
    background: #0077FF;
  }

  .el-icon-close:before {
    color: #fff;
  }
}
.resume-progress{
  .el-progress__text{
    font-size: 16px !important;
  }
 }
.userFormStyle {
  .el-input__inner {
    width: 211px;
  }
}
.positionDialog{
  .el-dialog__body{
    padding: 10px 20px;
    height: 530px;
    overflow:auto;
  }
  .el-tag{
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
</style>
